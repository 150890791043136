function normalizeURL(url: string): string {
  const HTTP_STRING = "http://"
  const HTTPS_STRING = "https://"

  const websiteUrl = url.replace(/ /g, "")

  if (websiteUrl.includes(HTTP_STRING) || websiteUrl.includes(HTTPS_STRING)) {
    return websiteUrl
  }

  return `https://${url}`
}

export { normalizeURL }
